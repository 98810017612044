/*-----------------------------------------------------------------------------------

    Template Name: Fanton - Consulting Business Template
    Template URI: http://rockstheme.com
    Description: This is html5 template
    Author: Rocks_theme
    Author URI: http://rockstheme.com
    Version: 1.0

-----------------------------------------------------------------------------------*/

    /*-----------------------------------------------------------------------------------  
     CSS INDEX
    ===================

    1. Theme Default CSS (body, link color, section etc)
    2. Header Top Area
       2.1 Header Bottom Area
       2.1 Sticky Header Area
       2.3. Mobile Menu Area
    3. Slider Area
    4.Welcome Services Area
    5.Services Area
    6.Counter Area
    7.project Area
    8.Quote Area
    9.Testimonial Area Css
    10. Overview  Area
    11. Blog Area
	12. Footer Area
	13.Home page area 2
	14.Home page area 3
	15.Home page area 4
	16. Breadcumbs Area
	17. About area
	18.Video area 
	19. team  Area
	20. Faq Area
	21. Error page
	22. Blog Details page
	23.Sub Services Pages
	24. Project pages
	25. Project Details page

    



/*----------------------------------------*/
/*  1.  Theme default CSS
/*----------------------------------------*/
html, body {
	height: 100%;
}
.floatleft {
	float:left;
}
.floatright {
	float:right;
}
.alignleft {
	float:left;
	margin-right:15px;
	margin-bottom: 15px;
}
.alignright {
	float:right;
	margin-left:15px;
	margin-bottom: 15px;
}
.aligncenter {
	display:block;
	margin:0 auto 15px;
}
a:focus {
	outline:0px solid;
}
img {max-width:100%;
	height:auto;
}
.fix {
	overflow:hidden;
}
p {
	margin:0 0 15px;
    color: #777;
}
h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
	margin: 0 0 15px;
	color: #444;
    font-weight: 700;
}
h1{
	font-size: 48px;
	line-height: 50px;
		
}
h2{
	font-size: 38px;
	line-height: 40px;
		
}
h3{
	font-size: 30px;
	line-height: 32px;
	font-family: 'Raleway', sans-serif;
		
}
h4{
	font-size: 24px;
	line-height: 26px;
		
}
h5{
	font-size: 20px;
	line-height: 22px;
		
}
h6{
	font-size: 16px;
	line-height: 20px;
		
}
a {
	transition: all 0.3s ease 0s;
	text-decoration:none;
}
a:hover {
  color: #47c010;
  text-decoration: none;
}
a:active, a:hover {
  outline: 0 none;
}
a:hover, a:focus {
    color: #333;
    text-decoration: none;
}
body {
	background: #fff none repeat scroll 0 0;
	color: #777;
    font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	text-align: left;
	overflow-x: hidden;
	line-height: 24px;
}
#scrollUp {
  background: #000 none repeat scroll 0 0;
  border-radius: 2px;
  bottom: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  color: #fff;
  font-size: 20px;
  height: 34px;
  line-height: 30px;
  opacity: 0.6;
  right: 15px;
  text-align: center;
  transition: all 0.3s ease 0s;
  width: 34px;
}
#scrollUp:hover {
    background: #47c010;
    opacity: 1;
}
.clear{
	clear:both;
}
ul{
	list-style: outside none none;
	margin: 0;
	padding: 0;
	
}

.infofont ul{
	list-style: outside none none;
	font-size:16px;
	margin: 0;
	padding: 0;
	color: #5d5858;
	margin-bottom:25px;
}
.infofont h2{font-size: 20px}
.infofont h3{font-size: 18px;}

.infofont ul li{margin-left:35px;list-style-type:disc;}
.infofont2 ul li{list-style-type:disc;}
input, select, textarea, input[type="text"], input[type="date"], input[type="url"], input[type="email"], input[type="password"], input[type="tel"], button, button[type="submit"] {
	-moz-appearance: none;
	box-shadow: none !important;
}
input:focus, textarea:focus, select:focus {
    outline: none;
}

.navbar-collapse {
    padding-left: 0px;
}
::-moz-selection {
    background: #47c010;
    text-shadow: none;
}
::selection {
    background: #47c010;
    text-shadow: none;
}
.color{
    color:#47c010;
    font-weight: 700;
}
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
.area-padding{
	padding: 70px 0px;
}

.area-padding-no-top{
    padding: 0px 0px 70px 0px;
}.area-padding-no-bottom{
    padding: 70px 0px 0px 0px;
}

.area-padding-2{
    padding: 70px 0px 50px;
}
.padding-2{
    padding-bottom: 90px;
}
.section-headline{
    margin-bottom: 60px;
    position: relative;
}
.section-headline2{
    margin-bottom: 0px;
    position: relative;
}
.section-headline h3 {
    display: inline-block;
    font-size: 40px;
    font-weight: 700;
    
}
.section-headline2 h3 {
    display: inline-block;
    font-size: 40px;
    font-weight: 700;

}
.section-headline.white-headline h3 {
    color:#fff;
}
.section-headline2.white-headline h3 {
    color:#fff;
}
.section-headline.text-left {
    margin-bottom: 30px;
}
.section-headline2.text-left {
    margin-bottom: 30px;
}
.section-headline::after {
    border: 1px solid #47c010;
    bottom: -20px;
    content: "";
    left: 0;
    margin: 0 auto;
    position: absolute;
    width: 60px;
    right: 0;
}
.section-headline.text-left h3::after {
    border: 2px solid #47c010;
    left: 0;
    right: auto;
}
.section-headline2.text-left h3::after {
    border: 2px solid #47c010;
    left: 0;
    right: auto;
}
.section-headline p {
    max-width: 680px;
    font-size: 16px;
    letter-spacing: 0.5px;
    margin: 0 auto;
    line-height: 26px;
}
.sub-head h4 {
    color: #fff;
    font-size: 30px;
    position: relative;
    padding-bottom: 30px;
}
.sub-head h4::after {
    border: 2px solid #47c010;
    bottom:0px;
    content: "";
    left: 0;
    margin: 0 auto;
    position: absolute;
    width: 20%;
}
.sub-head p {
    color: #fff;
    font-size: 17px;
    margin-top: 20px;
    line-height: 26px;
}
.mar-row{
    margin-top: 30px;
}
.load-more-btn {
    text-transform: uppercase;
    background: #fff;
    display: inline-block;
    padding: 10px 20px;
    color: #47c010;
    font-weight: 600;
    margin-top: 30px;
    border-radius: 3px;
    width: 200px;
	transition: 0.4s;
	border:1px solid #47c010;
	width:100%;
	text-align:center;
}
.load-more-btn:hover{
    background: #47c010;
	border:1px solid #47c010;
    color: #fff;
	transition: 0.4s;
}
.bg-color{
	background: #f9f9f9;
}
:focus {
    outline: -webkit-focus-ring-color auto 0px;
}
/*--------------------------------*/
/*  2. Header top Area
/*--------------------------------*/
.topbar-left ul li {
    display: inline-block;
}
.topbar-area {
    background:#243559;
}
.topbar-left ul li a {
    color: #ddd;
    display: block;
    font-weight: 600;
    margin-right: 20px;
    padding: 15px 0;
    text-decoration: none;
    font-size: 16px;
}
.topbar-left ul li a i {
    color: #47c010;
    font-size: 16px;
    padding-right: 7px;
}
.topbar-right {
    position: relative;
    display: block;
}
.top-social {
    float: right;
    padding: 15px 0px;
    margin-right: 10px;
}
.top-social ul li{
    display: inline-block;
}
.top-social ul li a {
    color: #ddd;
    margin-right: 5px;
    display: block;
    text-align: center;
    font-size: 15px;
    width: 26px;
    height: 26px;
    border-radius: 5px;
    line-height: 25px;
}
.top-social ul li a:hover{
	color:#fff;
	background: #47c010;
}
.quote-button {
    float: right;
}
.quote-button .quote-btn {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800;
    color: #fff;
    background: #47c010;
    display: inline-block;
    border: 2px solid #47c010;
    padding: 14px 20px;
    margin: 0px;
}
.quote-button .quote-btn:hover {
	background: transparent;
	color: #47c010;
}
.slogan {
    margin-bottom: 0px;
    padding: 16px 0px;
    color: #fff;
    font-size: 16px;
}
/*----------------------------------------*/
/*  2.1 Header Bottom Area 
/*----------------------------------------*/
.header-area{
	background: #fff;
	box-shadow: 2px 2px 5px #ccc, 0px 0px 0px #ccc;
}
.logo {
    display: block;
}
.logo a {
    display: inline-block;
    height: auto;
    padding: 25px 0;
}
.main-menu ul.navbar-nav li {
    float: left;
    position: relative;
}
.main-menu ul.navbar-nav > li >a {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    color: #444;
    font-size: 14px;
    font-weight: 700;
    padding: 45px 0px;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
	margin: 0px 15px;
}
.main-menu ul.navbar-nav li a:hover{
	color: #47c010;
}
.main-menu ul.navbar-nav > li > a::before {
    background: #47c010;
    content: "";
    height: 2px;
    position: absolute;
    top: 0;
    width: 0;
    transition: .5s;
	left: 0px;
}
.main-menu ul.navbar-nav >li:hover > a:before, .main-menu ul.navbar-nav >li.active > a::before {
    width: 100%;
}
.main-menu ul.navbar-nav li.active a:focus {
    color: #47c010;
}
.main-menu ul.navbar-nav li.active a {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    color: #47c010;
    position: relative;
    z-index: 9999999;
}
.navbar {
    border: medium none;
    margin-bottom: 0;
}
.navbar-default {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
}
.main-menu ul.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    background: none;
    color:#333;
}
.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
    background-color: transparent;
    color: #47c010;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background: none;
}
.main-menu ul.nav li ul.sub-menu {
    background: #fff;
    border-top: 2px solid #47c010;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 115%;
    transition: all 0.3s ease 0s;
    visibility: hidden;
    width: 220px;
    z-index: -99;
}
.pagess {
    position: relative;
}
.navbar.navbar-default {
    float: right;
}
.main-menu ul.nav li ul.sub-menu li {
    border-bottom: 1px solid #ddd;
    padding: 0;
    position: relative;
    width: 100%;
}
.main-menu ul.nav li:hover ul.sub-menu{
    top:100%;
    opacity:1;
    z-index:999;
    visibility:visible;
}
.main-menu ul.nav li ul.sub-menu li a {
    color: #444;
    display: block;
    font-size: 13px;
    font-weight: 700;
    padding: 10px 15px;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
}
.main-menu ul.nav li ul.sub-menu li a span{
    color: #47c010;
}
.main-menu ul.nav li:hover ul.sub-menu li a:hover{
    color: #47c010;
}
.main-menu ul.nav li ul.sub-menu li:last-child {
    border:none;
}
.stick span.search-button i,.stick .header-search input{color:#444}
.search-option-5 button.button {
    display: inline-block;
    border: none;
    background: none;
    padding: 0;
}
.search-option {
    background: #fff none repeat scroll 0 0;
    bottom: -48px;
    color: #444;
    display: none;
    position: absolute;
    right: 20px;
    width: 260px;
    z-index: 99999;
}
.search-option input {
    background: #fff none repeat scroll 0 0;
    border: medium none;
    color: #444;
    display: block;
    float: left;
    font-size: 14px;
    font-style: italic;
    padding: 8px 10px;
    width: 210px;
    height: 44px;
}
.search-option button.button {
    background: #47c010;
    border: medium none;
    color: #fff;
    display: inline-block;
    float: right;
    font-size: 17px;
    line-height: 35px;
    padding: 5px 15px;
    text-align: center;
}
.header-right-link a.main-search {
    color: #fff;
    display: inline-block;
    float: right;
    font-size: 16px;
    line-height: 39px;
    position: relative;
    text-align: center;
    width: 40px;
    height: 40px;
    background: #47c010;
    border-radius: 5px;
    margin: 35px 0px 35px 10px;
}
/*--------------------------------*/
/* 2.2. Sticky Header Area
/*--------------------------------*/
.header-area.stick {
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999;
    box-shadow: 0px 0px 5px #000, -2px -2px 5px #000;
    background: #eeeeee;
}
.header-area.stick .logo a {
    display: inline-block;
    height: auto;
    padding: 11px 0;
}
.header-area.stick .main-menu ul.navbar-nav li a {
    padding: 25px 0px;
}
.header-area.stick .quote-button {
    float: right;
    padding: 13px 5px;
}
.header-area.stick .header-right-link a.main-search {
    margin: 15px 10px;
}
.header-area.stick .main-menu ul.nav li ul.sub-menu li a {
    color: #444;
    display: block;
    font-size: 13px;
    font-weight: 700;
    padding: 10px 15px;
}
.header-area.stick .main-menu ul.nav li ul.sub-menu li a:hover{
    color: #47c010;
}
.header-area.stick .navbar-brand>img {
    display: block;
    width: 160px;
    height: auto;
}
/*----------------------------------------*/
/*  2.3. Mobile Menu Area
/*----------------------------------------*/
.mobile-menu-area {
    background: #f9f9f9 none repeat scroll 0 0;
    padding: 10px 0px ;
}
.mean-container .mean-bar::after {
    content: "";
    font-size: 21px;
    left: 5%;
    position: absolute;
    top: 12px;
    text-transform: uppercase;
    font-weight: 500;
}
.mean-container a.meanmenu-reveal:hover {
    color:#252525
}
.mean-container .mean-nav ul {
    list-style-type: none;
    margin: 20px 0px;
    padding: 0;
    width: 100%;
}
.mean-container .mean-nav ul li a {
    background: #f8f8f8 none repeat scroll 0 0;
    color: #444;
    display: block;
    float: left;
    font-size: 12px;
    font-weight: 700;
    margin: 0;
    padding: 13px 10px;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    width: 90%;
}
.mean-nav ul li.mean-last {
    margin-bottom: 20px;
}
.mean-container .mean-nav ul li a.mean-expand {
    color: #444;
    line-height: 17px;
}
.mean-container .mean-nav ul li {
    width: 99.7%;
}
/*----------------------------------------*/
/*  3. Slider Area
/*----------------------------------------*/
.intro-content {
    position: relative;
    z-index: 1;
}
.intro-content::after {
    background: rgba(10, 27, 40, 0) none repeat scroll 0 0;
    content: "";
    height: 301px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}
.slider-content {
    position: absolute;
    right: 0;
    text-align: left;
    top:32%;
    z-index: 3;
    left: 0;
    margin: 0 auto;
    margin-top: -170px;
}
.layer-1-2 {
    margin: 20px 0;
}
.layer-1-1 p {
    color: #fff;
    font-size: 19px;
    letter-spacing: 0.60px;
    line-height: 30px;
    
}
.layer-1-2 h1 {
    color: #fff;
    font-size: 50px;
    font-weight: 700;
    line-height: 60px;
    text-transform: uppercase;
    
    letter-spacing: 2px;
}
.ready-btn {
    border: 1px solid #47c010;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    margin-top: 20px;
    padding: 12px 10px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.4s ease 0s;
    z-index: 222;
    background: #47c010;
    width: 140px;
    border-radius: 3px;
}
.ready-btn:hover{
    color: #fff;
    background: #47c010;
    border: 1px solid #47c010;
    text-decoration: none;
}
.ready-btn.right-btn {
    margin-left: 15px;
    background: #252525;
    border: 1px solid #252525;
}
.ready-btn.right-btn:hover{
    background: #47c010;
    border: 1px solid #47c010;
}
.ready-btn.left-btn:hover{
    background: #252525;
    border: 1px solid #252525;
}
.layer-1-2 .color {
    color: #47c010;
    font-weight: 900;
}
.intro-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-prev, 
.intro-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-next {
    color: #fff;
    font-size: 18px;
    margin-top: -20px;
    position: absolute;
    top: 50%;;
    text-align: center;
    line-height: 39px;
    opacity: 0;
    border:1px solid #fff;
    width: 40px;
    height: 40px;
	border-radius: 50%;
}
.intro-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-prev{
    left: 10%;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    -ms-transition: 0.4s;
}
.intro-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-next {
    right: 10%;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    -ms-transition: 0.4s;
}
.intro-carousel.owl-carousel.owl-theme:hover .owl-controls .owl-nav div.owl-next{
    right: 2%;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    -ms-transition: 0.4s;
    opacity: 1;
}
.intro-carousel.owl-carousel.owl-theme:hover .owl-controls .owl-nav div.owl-prev{
    left: 2%;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    -ms-transition: 0.4s;
    opacity: 1;
}
.intro-carousel.owl-carousel.owl-theme:hover .owl-controls .owl-nav div.owl-next:hover,
.intro-carousel.owl-carousel.owl-theme:hover .owl-controls .owl-nav div.owl-prev:hover{
    color:#fff;
    background: #47c010;
    border: 1px solid #47c010;
}
.intro-carousel .owl-item .layer-1-2{
    transform: translateY(100%);
    opacity: 0;
    transition: all 1s ease-in-out 0.3s;
}
.intro-carousel .owl-item .layer-1-1{
    transform: translateY(100%);
    opacity: 0;
    transition: all 1s ease-in-out 1s;
}
.intro-carousel .owl-item .layer-1-3{
    transform: translateY(100%);
    opacity: 0;
    transition: all 1s ease-in-out 1.5s;
}
.intro-carousel .owl-item.active .layer-1-2,
.intro-carousel .owl-item.active .layer-1-1,
.intro-carousel .owl-item.active .layer-1-3{
    transform: translateY(0);
    opacity: 1;
}
.intro-carousel .owl-item.active .slider-images img{
	transform: scale(1.1);
	transition: 10s;
}
/*--------------------------------*/
/* 4.Welcome Services Area
/*--------------------------------*/
.section-headline h4 {
    font-size: 24px;
    max-width: 800px;
    line-height: 36px;
    font-weight: 700;
    margin: 0 auto;
    text-transform: uppercase;
}
.section-headline h4 .color {
    color: #47c010;
    font-weight: 600;
}
.well-services {
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
    z-index: 1;
    background: #fff;
}
.top-icon {
    position: absolute;
    right: 15px;
    font-size: 44px;
    bottom: 0px;
    background: #47c010;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    color: #fff;
    border-radius: 5px 5px 0px 0px;
	z-index: 9;
}
.main-services {
    display: block;
    overflow: hidden;
    padding: 20px;
    position: relative;
    z-index: 1;
}
.well-icon {
    width: 60px;
    float: left;
    height: 100px;
    font-size: 40px;
    color: #47c010;
}
.services-img {
    overflow: hidden;
    position: relative;
    text-align: center;
}
.services-img img {
    transform: scale(1.1);
    transition: 0.4s;
}
.image-layer {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 100%;
    background: rgba(0,44,87,0.95) none repeat scroll 0 0;
    z-index: 1;
    opacity: 1;
	transition: 0.4s;
	padding: 30px 20px;
}
.image-layer a {
    display: block;
    text-align: left;
    position: relative;
    margin-bottom: 10px;
    color: #fff;
	font-size: 14px;
}
.image-layer a i {
    border-radius: 50%;
    background: transparent;
    font-size: 20px;
    opacity: 0.90;
    line-height: 30px;
    margin-right: 15px;
}
.service-content h4 {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
    padding: 0 0 10px;
    text-transform: uppercase;
}
.service-btn {
    font-weight: 700;
    text-transform: uppercase;
    color: #444;
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    z-index: 2;
}
.service-btn::after {
    position: absolute;
    content: "\f105";
    font-family: fontAwesome;
    right: -12px;
    top: -3px;
}
.well-services:hover .services-img img {
    transform: scale(1);
    transition: 0.4s;
}
.well-services:hover .image-layer {
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.4s;
}
.well-services:hover .service-content h4{
    color: #47c010;
}
.well-services:hover .service-btn:hover{
    color: #47c010;
}
.image-layer a:hover{
   color: #47c010;
}
/*--------------------------------*/
/* 5.Services Area
/*--------------------------------*/
.services-area{
    background: #fff;
}
.services-all{
	margin-top: -20px;
}
.single-services {
    box-shadow: none;
    margin-top: 60px;
    position: relative;
    z-index: 1;
    border: 1px solid #47c010;
    padding: 30px 30px;
	z-index: 1;
	border-radius:10px;
}
.service-images {
    display: inline-block;
    font-size: 40px;
    line-height: 67px;
    color: #47c010;
    transition: 0.4s;
    position: absolute;
    top: -30px;
    z-index: 9999;
    width: 70px;
    height: 70px;
    background: #fff;
    text-align: center;
    border-radius: 5px;
	transition: 0.4s;
	border: 1px solid #47c010;
}
.services-all .service-content {
    padding-top: 30px;
}
.single-services::after {
    background: #fff;
   /* border: 1px solid #41b10f;*/
    width: 100%;
    height: 100%;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.4s;
    z-index: -1;
	border-radius:10px;
}
.single-services:hover::after {
    opacity: 1;
    transition: 0.4s;
}
.single-services:hover{
	border: 1px solid #41b10f;

}
.single-services .service-content h4 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
}
.single-services .service-content h4 a {
    color: #444;
}
.single-services .service-content p{
	margin-bottom: 0px;
}
.single-services:hover .service-content h4 a, 
.single-services:hover .service-content p{
    color: #444;
}
.single-services:hover .service-images {
    color: #fff;
    background:#47c010;
	transition: 0.4s;
}
/*----------------------------------------*/
/* 6.Counter Area
/*----------------------------------------*/
.counter-area{

}
.counter-inner {
    background: #f6f6f6;
    padding: 100px 30px 100px 70px;
}
.parallax-bg{
    position:relative;
}
.counter-area.parallax-bg:before{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background:#002c57;
    opacity:.8;
    content:"";
}
.fun_text {
    text-align: center;
    background: rgba(0,44,87,0.95);
    padding: 30px 15px;
}
.fun_text:nth-child(3n){
	margin-right: 0px;
}
.fun_text span {
    color: #47c010;
    display: block;
    font-size: 36px;
    font-weight: bold;
    padding-bottom: 10px;
    line-height: 40px;
}
.fun_text > h5 {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0;
}
/*--------------------------------*/
/*  7.project Area
/*--------------------------------*/
.single-awesome-project {
    float: left;
    overflow: hidden;
    width:100%;
}
.project-all{
    margin-top: -40px;
}
.project-all .single-awesome-project {
    margin-top: 40px;
}
.first-item {
    margin-bottom: 30px;
}
.project-carousel .col-md-4{
	width: 100%;
}
.awesome-img{
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}
.awesome-img img{
    transform: scale(1.1);
    transition: 0.4s;
}
.single-awesome-project:hover .awesome-img img{
    transform: scale(1);
    transition: 0.4s;
}
.awesome-img > a {
    display: block;
    position: relative;
}
.single-awesome-project:hover .awesome-img > a::after{
    opacity: 1;
}
.single-awesome-project:hover .add-actions{
    opacity: 1;
}
.awesome-img > a::after {
    background: rgba(0,44,87,0.95);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0;
    transition: 0.4s;
}
.add-actions {
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    transition: all 0.4s ease 0s;
    width: 100%;
    z-index: 9999;
    top: 0;
    cursor: pointer;
}
.port-icon {
    color: #fff;
    display: inline-block;
    cursor: pointer;
    top: 41%;
    position: relative;
    font-size: 40px;
}
.project-dec {
    display: block;
    padding: 20px 0px 0px 0px;
}
.project-dec h4 {
    color: #444;
    font-size: 22px;
    font-weight: 500;
    text-decoration: none;
    text-transform: capitalize;
    margin-bottom: 5px;
    cursor: pointer;
}
.project-dec a {
    color: #444;
}
.project-dec a:hover {
    color: #333;
}
.project-dec span {
    font-size: 14px;
}
.project-dec p{
	margin-bottom: 0px;
}
.pst-content {
    padding-left: 10px;
}
.single-awesome-project:hover .project-dec h4 {
    color:#47c010;
}
.project-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-prev, 
.project-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-next {
    color: #fff;
    font-size: 18px;
    margin-top: -20px;
    position: absolute;
    top: 50%;;
    text-align: center;
    line-height: 39px;
    opacity: 0;
    border:1px solid #fff;
    width: 40px;
    height: 40px;
	border-radius: 50%;
}
.project-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-prev{
    left: 10%;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    -ms-transition: 0.4s;
}
.project-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-next {
    right: 10%;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    -ms-transition: 0.4s;
}
.project-carousel.owl-carousel.owl-theme:hover .owl-controls .owl-nav div.owl-next{
    right: 2%;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    -ms-transition: 0.4s;
    opacity: 1;
}
.project-carousel.owl-carousel.owl-theme:hover .owl-controls .owl-nav div.owl-prev{
    left: 2%;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    -ms-transition: 0.4s;
    opacity: 1;
}
.project-carousel.owl-carousel.owl-theme:hover .owl-controls .owl-nav div.owl-next:hover,
.project-carousel.owl-carousel.owl-theme:hover .owl-controls .owl-nav div.owl-prev:hover{
    color:#fff;
    background: #47c010;
    border: 1px solid #47c010;
}
/*----------------------------------------
 8.Quote Area
----------------------------------------*/

.quote-area {
    background:#41413f;
	background-image: url('../../public/img/np-bg.png');
    background-repeat: repeat-x;
	background-position: bottom;
	/*height: auto;*/
	width: 100%;
}

	
	

	
.single-contact h5 {
    color: #fff;
    text-transform: uppercase;
    margin-bottom:15px;
}
.single-contact a {
    display: block;
    margin-bottom: 10px;
}
.single-contact a i {
    float: left;
    color: #fec731;
    font-size: 18px;
    padding-right: 20px;
    line-height: 24px;
}
.single-contact span {
    color: #fff;
}
.form-row{
  margin-top: 60px;
}
.quote-area #contactForm input {
    border: none;
    border-radius: 0;
    height: 46px;
    margin-bottom: 20px;
}
.quote-area #contactForm input.last-part{
	margin-bottom: 0px;
}
.quote-area .contact-form textarea#message{
    
    border-radius: 0;
    border: none;
    margin-bottom: 20px;
}
.quote-area .quote-btn {
    background: #47c010;
    border: 1px solid #47c010;
    color: #fff;
    padding: 10px 20px;
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
    width: 100%;
}
.quote-area .quote-btn:hover{
    background: #40af0e;
    color:#fff;
}
.help-block.last-part{
	margin:0px;
}
/*--------------------------------*/
/* 9.Testimonial Area Css
/*--------------------------------*/
.testimonial-area{
    background:#f9f9f9;
}
.single-testi {
    padding: 30px 20px;
    position: relative;
    border: 1px solid #f5f5f5;
	background: #fff;
}
.testi-img {
    margin-bottom: 30px;
}
.testi-img img {
    width: 100%;
    max-width: 80px;
    height: auto;
    border-radius: 50%;
    float: left;
}
.client-rating {
    padding-bottom: 10px;
}
.client-rating a {
    display: inline-block;
    color: #fec731;
    font-size: 18px;
}
.texti-name {
    padding-left: 20px;
    margin-top: 15px;
    display: inline-block;
}
.texti-name h4 {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 15px;
    margin-bottom: 0px;
}
.guest-rev a{
	color: #444; 
}
.testi-text p {
    color: #444;
    margin-bottom: 0px;
}
.testi-text h5 {
    color: #444;
    font-size: 20px;
}
.testi-text a{
  color: #47c010;  
}
.Reviews-content {
    margin-top: -30px;
}
.testimonial-carousel.item-indicator{
    padding: 30px 0px 50px;
}
.testimonial-carousel.owl-carousel.owl-theme .owl-controls .owl-dots div.owl-dot > span {
    background: #444 none repeat scroll 0 0;
    display: inline-block;
    height: 8px;
    width: 8px;
    -moz-transition:0.4s;
    -webkit-transition:0.4s;
    -o-transition:0.4s;
    -ms-transition:0.4s;
    transition:0.4s;
    border-radius: 50%;
}
.testimonial-carousel.owl-carousel.owl-theme .owl-controls .owl-dots {
    bottom: 0px;
    display: block;
    left: 50%;
    margin-left: -20px;
    position: absolute;
}
.testimonial-content {
    margin-bottom: 15px;
}
.testimonial-carousel.owl-carousel.owl-theme .owl-controls .owl-dots div.owl-dot {
    display: inline-block;
    margin: 0 3px;
}
.testimonial-carousel.owl-carousel.owl-theme .owl-controls .owl-dots div.owl-dot.active span{
    background: #47c010;
}
.reviews-page .single-testi{
    margin-bottom: 30px;
}
.Reviews-content .active.center .single-testi{
	background:#002c57;
}
.Reviews-content .active.center .texti-name h4,
.Reviews-content .active.center .testi-text p,
.Reviews-content .active.center .guest-rev a {
    color: #fff;
}
/*----------------------------------------*/
/*  10. Overview  Area
/*----------------------------------------*/
.overview-area{

}
.parallax-bg{
    position:relative;
}
.overview-area.parallax-bg:before{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background:#002c57;
    opacity:.6;
    content:"";
}
.main-view {
    padding-bottom: 15px;
}
.main-view:last-child{
	padding-bottom: 0px;
}
.company-content {
    background: #fff;
    padding: 85px 60px 85px 30px;
}
.view-images {
    float: left;
    font-size: 28px;
    width: 60px;
    height: 60px;
    border: 2px solid #fec731;
    line-height: 54px;
    text-align: center;
    color: #fff;
    background: #fec731;
    border-radius: 50px;
	transition: 0.4s;
}
.view-content {
    padding-left: 80px;
}
.main-view:last-child p{
	margin-bottom: 0px;
}
.single-view:hover .view-images{
	border: 2px solid #fec731;
	background: transparent;
	color:#fec731;
	transition: 0.4s;
}
.view-content h4 {
    text-transform: capitalize;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
}
.client-details {
    padding: 12% 10%;
    text-align: center;
    display: block;
    overflow: hidden;
}
.client-head {
    text-align: left;
    margin-left: 40px;
    padding-left: 20px;
    position: relative;
}
.client-head::before{
    position: absolute;
    content: "";
    width: 5px;
    height: 80px;
    background: #fec731;
    left:0;
    top:0px;
}
.client-head h3 {
    color: #fff;
    text-transform: capitalize;
    margin-bottom: 5px;
}
.client-head p {
    color: #fff;
}
.single-client {
    float: left;
    width: 50%;
    padding: 15px 5px;
}
.single-client a {
    display: inline-block;
    opacity: 0.7;
    transition: 0.4s;
    border: 1px solid #fff;
    background: #fff;
}
.single-client a:hover{
    opacity: 1;
    transition: 0.4s;
}
/*----------------------------------------*/
/*  11. Blog Area
/*----------------------------------------*/
.blog-area{
  background: #fff;
}
.blog-grid {
    margin-top: -30px;
}
.blog-image{
  overflow: hidden;
    display: block;
}
.image-scale {
    position: relative;
    transform: scale(1.1);
    display: block;
    transition: 0.5s;
}
.image-scale::after{
    position: absolute;
    content: "";
    background: rgba(0,46,91,0.10);
    width: 100%;
    height: 100%;
    left:0;
    top:0;
}
.blog-content a h4 {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 600;
    color: #444;
    line-height: 26px;
    margin-top: -20px;
    margin-bottom: 10px;
}
.blog-details .blog-content a h4 {
    font-size: 24px;
    line-height: 32px;
}
.home-blog .blog-content a h4{
	margin-bottom: 0px;
}
.blog-meta {
    padding: 15px 0px 10px;
    position: relative;
    top: -41px;
    background: #47c010;
    padding: 10px 15px;
	max-width: 180px;
}
.blog-meta span {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    padding-right: 10px;
}
.single-blog:hover .image-scale {
  transform: scale(1);
  transition: 0.5s;
}
.single-blog:hover .image-scale::after{
    background: rgba(0,46,91,0);
}
.single-blog {
    margin-top: 30px;
    position: relative;
    overflow: hidden;
}
.blog-grid-fast {
    margin-top: -30px;
}
.single-blog.b-mar{
    margin-top: 20px;
}
.blog-content a h4:hover{
    color:#47c010;
}
.blog-btn {
    display: inline-block;
    font-weight: 700;
    color: #444;
    position: relative;
    text-transform: uppercase;
    font-size: 12px;
}
.blog-btn::after {
    position: absolute;
    content: "\e876";
    right:-12px;
    top: 0px;
    font-family: fanton;
    font-size: 10px;
    transition: 0.5s;
}
.blog-btn:hover {
    color: #47c010;
}
.blog-btn:hover.blog-btn::after {
    right: -20px;
    opacity: 1;
    transition: 0.5s;
}
/*----------------------------------------*/
/*  12. Footer Area
/*----------------------------------------*/
.footer-area {
    padding: 70px 0;
    background: #e3e3e3;
}
.footer-logo{
	margin-bottom: 15px;
}
.footer-head p {
    color: #777;
}
.footer-head h4 {
    color: #ddd;
    font-size: 18px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 700;
}
.suscribe-input input {
    background: transparent;
    border: medium none;
    color: #777;
    font-size: 15px;
    line-height: 24px;
    padding: 11px 15px;
    height: 42px;
    border-radius: 0;
    width: 100%;
    border: 1px solid #777;
}
.suscribe-input button {
    background: #47c010;
    border: 1px solid #47c010;
    color: #fff;
    font-size: 16px;
    padding: 8px 20px;
    border-radius: 3px;
    transition: 0.4s;
    margin-top: 15px;
    text-transform: uppercase;
}
.suscribe-input button:hover {
    background: transparent;
    border: 1px solid #47c010;
    color: #47c010;
    transition: 0.4s;
}
.footer-icons ul li {
    display: inline-block;
}
.footer-icons ul li a {
    color: #ddd;
    display: block;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    margin-right: 5px;
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
	border-radius: 50%;
}
.footer-tags li a:hover,
.footer-icons ul li a:hover{
    color: #fff;
	background: #47c010;
	border:1px solid #47c010;
}
.footer-tags li {
    float: left;
}
.footer-tags li a {
    color: #ddd;
    display: block;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    margin: 3px;
    border: 1px solid #002C57;
    background: #002C57;
    font-weight: 600;
    padding: 3px 5px;
    border-radius: 2px;
}
.footer-icons {
    margin-top: 20px;
}
.footer-contacts p span {
    color: #47c010;
    font-weight: 700;
}
.footer-content {
    display: block;
    overflow: hidden;
}
.footer-list li a {
    color: #ddd;
    padding: 3px 0px 3px 20px;
    display: block;
    position: relative;
    font-weight: 500;
}
.subs-feilds {
    margin-top: 20px;
}
.footer-list li:first-child a{
	padding-top: 0px;
}
.footer-list li:last-child a{
	padding-bottom: 0px;
}
.footer-list li a::after {
    position: absolute;
    content: "\e876";
    right: auto;
    top: 2px;
    font-family: fanton;
    font-size: 12px;
    left: 0;
}
.footer-list li a:hover {
    color: #47c010;
}
.footer-area-bottom {
    background: #41413f none repeat scroll 0 0;
    padding: 25px 0;
	border-top: 1px solid #33a795;
}
.copyright-text a:hover{
	text-decoration: underline;
	color:#47c010;
}
.copyright-text a {
    color: #ddd;
}
.copyright > p {
    margin-bottom: 0;
    color: #fff;
}
.copyright a {
    color: #fff;
}
.single-brand-item a {
    border: 1px solid #f5f5f5;
    display: block;
}
/*--------------------------------*/
/* 13.Home page area 2
/*--------------------------------*/
.header-two{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 999;
}
.topbar-area-2{
	background:transparent;
}
.header-area-2{
	background:transparent;
	box-shadow:none;
	border-top: 1px solid rgba(255,255,255,0.2);
}
.topbar-area-2 .topbar-left ul li a{
	color: #fff;
}
.header-area-2 .main-menu ul.navbar-nav li a{
	color: #fff;
}
.intro-area-2 .slider-content {
    margin-top: -120px;
}
.header-area-2.stick {
    background: #252525;
}
.footer-area-2 {
    background: #111;
}
.footer-area-2 .footer-tags li a {
    border: 1px solid #222;
    background: #222;
}
.footer-area-bottom-2 {
    background: #111 none repeat scroll 0 0;
	border-top: 1px solid #333;
}
/*--------------------------------*/
/* 14.Home page area 3
/*--------------------------------*/
.header-middle-area .header-info {
    width: 33.33%;
    float: left;
    padding: 33px 0px;
}
.header-middle-area .header-info-text span {
    display: block;
    font-size: 14px;
}
.header-middle-area .info-simple{
	font-weight: 700;
	color: #777;
	font-size:12px;
}
.header-middle-area .header-icon {
    float: left;
    margin-right: 10px;
}
.header-middle-area .logo a{
	padding: 25px 0;
}
.header-icon i {
    color: #47c010;
    font-size: 34px;
    line-height: 40px;
}
.info-first {
    font-weight: 700;
	color: #444;
}
.header-area-3 {
    background:#fff;
	border-top:1px solid rgba(246,177,41,0.20);
	box-shadow: none;
}
.header-area-3.stick {
    background:#fff;
}
.header-area-3 .navbar.navbar-default {
    float: left;
}
.header-area-3 .main-menu ul.navbar-nav li a {
    padding: 20px 15px;
	color: #444;
	margin: 0px;
}
.header-area-3.stick .main-menu ul.navbar-nav li a {
	padding: 25px 15px;
}
.header-area-3 .main-menu ul.navbar-nav li.active >a,
.header-area-3 .main-menu ul.navbar-nav > li > a:hover{
    background:#47c010;
	color:#fff;
}
.header-area-3 .header-right-link a.main-search {
    margin: 10px 0px 10px 10px;
}
.header-area.stick .header-right-link a.main-search {
    margin: 15px 0px 15px 10px;
}
.footer-area-3 .footer-tags li a {
    border: 1px solid #222;
    background: #222;
}
.footer-area-3 {
    background: #262C3E;
}
.footer-area-bottom-3 {
    background: #071333 none repeat scroll 0 0;
	border-top: 1px solid #333;
}
.intro-area-3 .layer-1-2 h1 {
    text-align: center;
    margin: 0 auto;
}
.intro-area-3 .layer-1-1 p{
	text-align: center;
	margin: 0px auto 15px;
}
.intro-area-3 .intro-carousel .owl-item .layer-1-3{
	text-align: center;
}
.footer-area-2 .footer-tags li a:hover,
.footer-area-3 .footer-tags li a:hover{
    color: #fff;
	background: #47c010;
	border:1px solid #47c010;
}
/*--------------------------------*/
/* 15.Home page area 4
/*--------------------------------*/
.intro-area-4 .layer-1-2 h1 {
    text-align: center;
    margin: 0 auto;
}
.intro-area-4 .layer-1-1 p{
	text-align: center;
	margin: 0px auto 15px;
}
.intro-area-4 .intro-carousel .owl-item .layer-1-3{
	text-align: center;
}
/*----------------------------------------*/
/*  16. Breadcumbs Area
/*----------------------------------------*/
.page-area{
    position: relative;
}
.page-area {
    background: url('../../public/img/infobg.png');
    background-repeat: repeat-x;
    
    background-position: top center;
}
.breadcumb-overlay {
  position: absolute;
  background: rgba(10, 27, 40, 0.6) none repeat scroll 0 0;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
}
.breadcrumb{
    padding: 100px 0px;
    background-color: transparent;
    margin-bottom: 0;
    display: block;
}

.breadcrumb2{
    padding: 30px 0px;
    background-color: transparent;
    margin-bottom: 0;
}
.breadcrumb .section-headline h3 {
    font-size: 48px;
    letter-spacing: 2px;
    line-height: 48px;
	text-transform: inherit;
}
.breadcrumb .section-headline{
    margin-bottom: 0px;
}
.breadcrumb .section-headline h3::after {
    display: none;
}
.breadcrumb ul {
    padding: 10px 0px;
}
.breadcrumb ul li {
    display: inline-block;
    color: #fff;
    padding: 0px 10px 0px 20px;
    position: relative;
    font-size: 16px;
}
.breadcrumb ul li.home-bread{
    padding-left: 0px;
}
.breadcrumb ul li.home-bread::after {
    position: absolute;
    content: "\f105";
    font-family: fontAwesome;
    right: -10px;
    top: 0;
}
.breadcrumb .section-headline::after {
    display: none;
}
.testimonial-page-area{
	background: #fff;
}
.welcome-page-area {
	background: #f9f9f9;
}
.pricing-page-area{
	background: #f9f9f9;
}
.testimonial-page-area .single-testi{
	margin-top: 30px;
}
/*----------------------------------------*/
/*  17. About area
/*----------------------------------------*/
.about-content h4 {
    font-weight: 600;
    line-height: 32px;
    text-transform: uppercase;
    font-size: 21px;
	position: relative;
}
.about-content h4 ::after {
    border: 1px solid #47c010;
    bottom: 0px;
    content: "";
    left: 0;
    margin: 0 auto;
    position: absolute;
    width: 12%;
}
.about-content p {
    font-size: 14px;
    letter-spacing: 0.50px;
    line-height: 24px;
}
.about-details {
    overflow: hidden;
    display: block;
    padding-top: 10px;
}
.icon-title{
	display: block;
	overflow: hidden;
}
.single-about {
    width: 48%;
    float: left;
    margin-right: 20px;
}
.single-about p{
	text-align: left;
}
.single-about:nth-child(2n),
.single-about:nth-child(4n){
	margin-right:0px;
}
.single-about:nth-child(3n),
.single-about:nth-child(4n){
	margin-bottom:0px;
}
.single-about a {
    font-size: 40px;
    color: #47c010;
    float: left;
    line-height: 49px;
}
.single-about h5 {
    font-size: 18px;
    color: #666;
    float: left;
    line-height: 50px;
    padding-left: 10px;
    margin-bottom: 0;
    font-weight: 600;
}
/*----------------------------------------
   18.Video area 
----------------------------------------*/
.video-text h4 {
    color: #444;
    line-height: 30px;
    font-size: 21px;
    font-weight: 600;
	text-transform: uppercase;
}
.video-text p {
    color: #444;
}

.video-inner::after{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    content:"";
    background: rgba(10, 27, 40, 0.2) none repeat scroll 0 0;
}
.video-content {
    width: 100%;
    height: 100%;
    min-height: 330px;
}
.video-play.vid-zone {
    background: #fff;
    border-radius: 100%;
    display: inline-block;
    font-size: 28px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    width: 60px;
    overflow: hidden;
    position: absolute;
    top: 40%;
    z-index: 999;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.video-play.vid-zone i {
    color: #47c010;
    margin-left: 5px;
}
.marker-list li {
    color: #444;
    padding-left: 30px;
    margin: 10px 0px;
    position: relative;
}
.marker-list li::after {
    position: absolute;
    content: "\e87f";
    left: 0;
    top: 3px;
    font-family: fanton;
    color: #47c010;
    font-weight: 700;
}
/*----------------------------------------*/
/*  19. team  Area
/*----------------------------------------*/
.team-area{
  background: #fff;
}
.team-member,page-team-member{
	margin-top: -30px;
}
.single-member {
    position: relative;
    overflow: hidden;
	margin-top: 30px;
}
.team-content {
    position: static;
    width: 100%;
    height: 100%;
    background: rgba(0,44,87,0.90) none repeat scroll 0 0;
    padding: 22px 20px;
    transition: 0.5s;
    min-height: 293px;
}
.team-share {
    opacity: 1;
}
.single-member:hover .team-content {
    transition: 0.5s;
    top:0px;
    padding: 22px 20px;
    background: rgba(0,44,87,0.99) none repeat scroll 0 0;
}
.single-member:hover .team-share {
    opacity: 1;
}
.team-title h4 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    color: #fff;
    margin-bottom: 5px;
}
.team-title h6 {
    color: #47c010;
}
.team-share p {
    color: #fff;
    letter-spacing: 1px;
}
.social-icon li{
    display: inline-block;
}
.social-icon li a {
    color: #fff;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-size: 18px;
    border-radius: 50%;
}
.social-icon li a:hover{
    color: #47c010;
}
.team-content .social-icon{
  margin-top: 15px;
}
/*--------------------------------*/ 
/* 20. Faq Area
/*--------------------------------*/
.testimonial-review .single-testi{
    margin-top: 30px;
}
.faq-details .panel-heading {
    padding: 0;
}
.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: none;
    border-radius: 4px;
   box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
}
.panel-default {
    border-color: #ddd;
    border-radius: 0 !important;
}
.panel-group .panel+.panel {
    margin-top: 10px;
}
.panel-default > .panel-heading {
    color: #666;
}
.panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #ddd;
    background: #fff;
}
.faq-details h4.check-title a {
    color: #666;
    display: block;
    font-weight: 500;
    padding: 10px 10px 10px 40px;
    text-decoration: none;
    background: #fff;
}
.panel-body {
    padding: 15px 15px 0px 45px;
}
.faq-details h4.check-title {
    color: #666;
    font-size: 16px;
    margin-bottom: 0px;
    text-transform: capitalize;
}
.faq-details a span.acc-icons {
    position: relative;
}
.faq-details a span.acc-icons::before {
    color: #666;
    content:"\e874 ";
    font-family: fanton;
    font-size: 16px;
    left: -26px;
    line-height: 39px;
    position: absolute;
    text-align: center;
    top: -10px;
}
.faq-details a.active span.acc-icons::before {
    color: #47c010;
    content: "\e873";
    font-family: fanton;
    font-size: 16px;
    left: -26px;
    line-height: 39px;
    position: absolute;
    text-align: center;
    top: -10px;
}
.faq-details .panel-heading h4 a.active {
    color: #47c010;
    background: #f9f9f9;
}
.faq-content {
    background: #f9f9f9;
    padding: 6% 10%;
    border-radius: 5px;
	box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
}
.faq-content .contact-form textarea#message {
    height: 135px;
}
.faq-page .quote-btn {
    font-weight: 600;
    color: #fff;
    background: #47c010;
    display: inline-block;
    border: 2px solid #47c010;
    padding: 10px 20px;
    width: 134px;
    text-transform: uppercase;
    border-radius: 3px;
	transition: 0.4s;
	margin-top: 10px;
}
.faq-page .quote-btn:hover{
    color: #47c010;
    background: #fff;
    border: 2px solid #47c010;
	transition: 0.4s;
}
/*--------------------------------*/ 
/* 21. Error page
/*--------------------------------*/
.error-easy-text {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 30px;
}
.high-text{
    font-size: 200px;
    line-height: 200px;
}
.error-bot{
    font-size: 24px;
}
.error-btn {
    background: #47c010;
    border: 2px solid #47c010;
    color: #fff;
    font-size: 16px;
    padding: 12px 20px;
    transition: 0.4s;
    margin-top: 15px;
    text-transform: capitalize;
    margin-top: 30px;
    display: inline-block;
	font-weight: 700;
}
.error-btn:hover{
    background: #fff;
    border: 2px solid #47c010;
    color: #47c010;
}
/*----------------------------------------*/
/*  20.	Contact Us page
/*----------------------------------------*/
.contact-details {
    display: block;
    overflow: hidden;
    background: #f5f5f5;
    padding: 40px 30px;
    margin-bottom: 40px;
}
.contact-head {
    background: #f9f9f9;
    padding: 40px 30px;
}
.contact-head h3 {
    font-weight: 600;
    font-size: 26px;
}
.contact-inner a i {
    color: #47c010;
    display: inline-block;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    margin-right: 15px;
    border-radius: 50%;
}
.contact-inner a {
    color: #666;
    font-size: 16px;
    display: block;
}
.contact-head p {
    font-size: 16px;
}
.contact-form input[type="text"], .contact-form input[type="email"] {
    border: 1px solid #ccc;
    border-radius: 0;
    height: 44px;
    margin-bottom: 15px;
    padding-left: 20px;
    width: 100%;
}
.contact-form textarea#message {
    border: 1px solid #ccc;
    border-radius: 0px;
    height: 180px;
    padding: 20px;
    width: 100%;
}
.contact-page .contact-btn {
    background: #47c010;
    border: 2px solid #47c010;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    margin-top: 5px;
    padding: 10px 40px;
    transition: 0.4s;
    width: auto;
    float: left;
    text-transform: uppercase;
	border-radius: 2px;
	font-weight: 700;
}
.contact-page .contact-btn:hover {
    background:transparent;
    border: 2px solid #47c010 ;
    color: #47c010;
    transition: 0.4s;
}
/*----------------------------------------
   22. Blog Details page
----------------------------------------*/
.blog-search-option input {
    padding: 5px 15px;
    width: 80%;
    border: none;
    height: 42px;
}
.blog-search-option {
    margin-bottom: 30px;
    display: block;
    border: 1px solid #ccc;
}
.blog-search-option button {
    background: transparent;
    border: none;
}
.blog-search-option button i {
    line-height: 16px;
    font-size: 20px;
    padding: 0px 10px;
}
.blog-search-option button i:hover {
    color: #47c010;
}
.left-blog h4 {
    border-bottom: 1px solid #ddd;
    color: #444;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 0;
    padding: 15px 0px;
    text-transform: uppercase;
}
.left-blog {
    overflow: hidden;
    padding-bottom: 20px;
}
.left-blog li {
    border-bottom: 1px solid #ddd;
    display: block;
}
.left-blog ul li a {
    color: #444;
    display: block;
    font-size: 16px;
    padding: 10px 0px;
    text-transform: capitalize;
    position: relative;
}
.left-blog ul li a:after {
    position: absolute;
    content: "\e876";
    font-family: fanton;
    right: 12px;
    top: 10px;
    font-size: 14px;
}
.popular-tag.left-blog ul li a:after {
    display: none;
}
.recent-single-post {
    border-bottom: 1px solid #ddd;
    display: block;
    overflow: hidden;
    padding: 15px 0px;
}
.recent-single-post:last-child {
    border-bottom: none;
}
.post-img {
    display: inline-block;
    float: left;
    padding: 0 5px;
    width: 35%;
}
.pst-content {
    display: inline-block;
    float: left;
    width: 65%;
    padding-left: 10px;
}
.pst-content p a:hover,.left-blog ul li a:hover {
    color: #47c010;
}
.left-blog-page {
    
}
.pst-content p a {
    color: #444;
    font-size: 15px;
}
.blog-tags {
    padding: 1px 0;
}
.left-blog li:last-child {
    border-bottom: 0 ;
}
.popular-tag.left-blog ul li a:hover{
    color: #fff;
}
.popular-tag.left-side-tags.left-blog ul {
    padding:0px;
}
.left-tags .left-side-tags ul li {
    border-bottom: 0;
    display: inline-block;
    margin: 5px 0px;
}
.left-tags .left-side-tags ul li a {
    padding: 3px 10px;
    width: auto;
    border: 2px solid #47c010;
    background: #47c010;
    color: #fff;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
}
.left-tags .left-side-tags ul li a:hover {
    border: 2px solid #47c010;
    background: #fff;
	color:#47c010
}
.left-side-tags h4 {
    margin-bottom: 15px;
}
.comments-heading h3, h3.comment-reply-title {
    border-bottom: 1px solid #e8e8e9;
    color: #444;
    font-size: 18px;
    margin: 0 0 20px;
    padding: 0 0 5px;
    text-transform: capitalize;
}
.comments-list ul li{
    margin-bottom: 25px;
}
.comments-list-img{
    float: left;
    margin-right: 15px;
}
.comments-content-wrap {
    color: #444;
    font-size: 14px;
    margin: 0 0 15px 60px;
    padding: 10px;
    position: relative;
}
.blog-content p {
    font-size: 15px;
	letter-spacing: 0.50px;
}
blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 15px;
    border-left: 5px solid #47c010;
    font-style: italic;
}
blockquote p {
    font-size: 16px !important;
    line-height: 28px;
}
.author-avatar {
    display: inline-block;
    float: left;
    width: 10%;
}
.author-description h2 {
    color: #777;
    font-size: 20px;
    text-transform: uppercase;
}
.author-description h2 a {
    color: #333;
}
.comments-content-wrap span b{margin-right:5px}
.comments-content-wrap span a:hover{}
span.post-time{margin-right:5px}
.comments-content-wrap p {
    color: #444;
    margin-top: 10px;
}
li.threaded-comments{margin-left:50px}
.comment-respond {
    margin-top: 20px;
}
span.email-notes{color: #42414f;
    display: block;
    font-size: 12px;
    margin-bottom: 10px;}
.comment-respond p {
    color: #444;
    margin-bottom: 5px;
}
.comment-respond input[type=text],.comment-respond input[type=email]{
    border: 1px solid #e5e5e5;
    border-radius: 0;
    height: 32px;
    margin-bottom: 15px;
    padding: 0 0 0 10px;
    width: 100%;}
.comment-respond textarea#message-box{
    border: 1px solid #e5e5e5;
    border-radius: 0;
    max-width: 100%;
    padding: 10px;
	height: 160px;
    width: 100%;
}
.comments-content-wrap span a {
    color: #47c010;
}
.comments-content-wrap span a:hover {
    color: #47c010;
}
.comment-respond .add-btn.contact-btn {
    background: #47c010;
    color: #fff;
    display: block;
    font-size: 16px;
    margin-top: 5px;
    padding: 12px 20px;
    transition: 0.4s;
    border-radius: 5px;
    width: auto !important;
    margin-top: 20px;
    border: 2px solid #47c010;
    text-transform: uppercase;
    font-weight: 700;
}
.comment-respond .add-btn.contact-btn:hover{
    background: #FFF;
	color: #47c010;
    border:2px solid #47c010;
}
.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 30px 0 0px;
    border-radius: 4px;
}
.pagination>li>a, 
.pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #fff;
    background-color: #333;
    border: none;
    margin-left: -1px;
    border-radius: 5px;
}
.pagination>li {
    display: inline-block;
    margin: 0px 3px;
}
.pagination>.active>a, 
.pagination>.active>span, 
.pagination>.active>a:hover, 
.pagination>.active>span:hover, 
.pagination>.active>a:focus, 
.pagination>.active>span:focus{
    z-index: 3;
    color: #fff;
    background-color: #47c010;
    border-color: #47c010;
    cursor: default;
    border-radius: 5px;
}
.pagination>li>a:hover, .pagination>li>span:hover, .pagination>li>a:focus, .pagination>li>span:focus {
    z-index: 2;
    color: #fff;
    background-color: #47c010;
    border-color: #47c010;
    border-radius: 2px;
}
.blog-page-details .left-blog-page {
    margin-top:0px;
    margin-bottom:30px;
}
.single-post-comments {
    margin-top: 30px;
}
/*----------------------------------------*/
/* 23.Sub Services Pages
/*----------------------------------------*/
.single-page-head {
    margin-bottom: 30px;
}
.single-services-page p{
    color: #666;
}
.left-menu li:first-child {
    margin: 0px 0px 2px
}
.left-contact h4 {
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 30px;
}
.left-menu li {
    display: block;
    margin: 5px 0;
}
.build-img .page-img {
    padding-bottom: 0;
}
.left-menu ul li.active a {
    background: #47c010 none repeat scroll 0 0;
    color: #fff;
    position: relative;
}
.left-menu ul li a:hover {
    color: #fff;
    background: #47c010; 
}
.left-menu ul li a {
    background: #f9f9f9;
    color: #444;
    display: block;
    font-size: 15px;
    font-weight: 700;
    padding: 14px 20px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
}
.left-menu ul li.active a::after,
.left-menu ul li a:hover::after {
    content: "\e876";
    font-family: fanton;
    font-size: 15px;
    position: absolute;
    right: 18px;
    top: 15px;
}
.download-btn h4 {
    text-transform: uppercase;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
}
.download-btn h4{
	text-transform: uppercase;
}
.down-btn i {
    font-size: 24px;
    float: right;
}
.down-btn {
    border: 1px solid #47c010;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 15px;
    padding: 15px;
    text-align: left;
    text-transform: uppercase;
    transition: all 0.4s ease 0s;
    z-index: 222;
    background: #47c010;
    width: 100%;
    position: relative;
    border-radius: 3px;
}
.down-btn.apli{
	margin-bottom: 0px;
}
.down-btn:hover{
    color:#47c010;
    background: #fff;
    border: 1px solid #47c010;
}
.single-page-head .clients-testi {
    padding: 20px 15px;
    border: 1px solid #47c010;
}
.single-page-head .review-text {
    margin-top: 20px;
}
.clients-testi .review-text a,
.clients-testi .guest-rev {
    color: #444;
}
.clients-testi .single-review {
    border: none;
}
.review-text h4 {
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 0px;
}
.single-page-head .review-text p {
    color: #666;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-style: italic;
}
.single-page-head .review-img {
    margin-top:0px;
}
.single-page-head .review-img img{
	border-radius: 50%;
}
.page-content h3 {
    font-weight: 600;
    padding: 15px 0 0;
}
.single-well {
    margin-top: 20px;
}
.single-page {
    overflow: hidden;
}
.single-well h3 {
    margin-bottom: 15px;
}
.single-well.last-part p{
	margin-bottom: 0px;
}
.service-list li {
    display: block;
    position: relative;
    padding-left: 30px;
    color: #444;
}
.service-list li::before{
    position: absolute;
    left: 0;
    content:"\f00c";
    font-family:fontawesome;
    top:0;
    color: #47c010;
}
.single-page-head .service-btn{
    width: 100%;
    margin: 20px 0px 0px 0px;
}
.single-services-page .marker-list li {
    color: #444;
}
.mar-row .single-well {
    margin-top: 0px;
}
/*--------------------------------*/
/* 24. Project pages
/*--------------------------------*/
.project-menu li a {
    color: #444;
    display: inline-block;
    font-size: 14px;
    padding: 5px 10px;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    font-weight: 700;
    letter-spacing: 0.5px;
    border-radius: 2px;
}
.project-menu li a.active,
.project-menu li a:hover {
    color: #fff;
    text-decoration: none;
	background: #47c010;
}
.project-menu {
    margin-bottom: 10px;
}
.project-menu li {
    display: inline-block;
}
.project-menu li a:hover {
    color: #fff;
    text-decoration: none;
}
.project-content .single-awesome-project{
	margin-top: 40px;
}
/*----------------------------------------
 25. Project Details page
----------------------------------------*/
.project-page-area .project-menu li a {
    color: #444;
}
.project-2-column .project-single {
    float: left;
    width: 50%;
}
.project-4-column .project-single {
    float: left;
    width: 25%;
}
.project-carousel-2.owl-carousel.owl-theme .owl-controls .owl-nav div {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 1px solid #fff;
    border-radius: 0;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    height: 40px;
    line-height: 38px;
    position: relative;
    text-align: center;
    transition: all 0.5s ease 0s;
    width: 40px;
    border-radius: 50%;
}
.project-carousel-2.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-prev {
    top: 50%;
    position: absolute;
    left: 3%;
    margin-top: -30px;
}
.project-carousel-2.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-prev:hover,
.project-carousel-2.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-next:hover{
    background: #47c010;
    border: 1px solid #47c010;
    color: #fff;
}
.project-carousel-2.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-next {
    margin-top: -30px;
    position: absolute;
    right: 3%;
    top: 50%;
}
.project-history {
    background: #f5f5f5;
    padding: 20px;
    margin-bottom: 30px;
}
.project-details h4 {
    font-size: 26px;
    font-weight: 600;
}
.project-name ul li {
    color: #444;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    margin: 10px 0px;
}
.project-name span {
    padding-right: 15px;
    min-width: 110px;
    display: inline-block;
    font-weight: 700;
    font-size: 18px;
}
.project-inner {
    margin-bottom: 40px;
}
.project-details p {
    font-size: 14px;
    letter-spacing: 0.5px;
    margin-bottom: 25px;
}
/*--------------------------------*/
/* .End CSS
/*--------------------------------*/
.bg{}
.navbar-light .navbar-nav .nav-link {
    padding-left: 15px;
    padding-right: 15px;
    color:#444;
    font-size: 15px;
    font-weight: 700;
    width:100%;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
}
.navbar-light .navbar-nav .nav-link:hover{color:#3e9d42;}
.fa-caret-right{margin-right:10px;color:#3e9d42;}
.dropdown-menu{min-width: 15rem;}
.dropdown-item{font-size: 14px;background-color:#fff;font-weight:light-bold;}
.dropdown-item:hover{background-color:#efefef;}
/*
.dropdown:hover >.dropdown-menu {
  display: block;
}
.dropdown:hover >.dropdown-menu >.dropdown-item{
  display: block;
}*/
.navbar {
    padding: 1rem 1rem;
}
.vasprvi{font-family: Teko;font-size:72px;color:#fff;letter-spacing:10px;text-transform:uppercase;margin-top:55px;margin-bottom:35px;}
.vasprvi4{font-family: Teko;font-size:60px;color:#000;letter-spacing:10px;text-transform:uppercase;margin-top:35px;margin-bottom:25px;}
.optimumgrupa{color:#DDD;text-transform:uppercase;margin-top:10px;}
.optimumgrupa4{color:#40b50e;text-transform:uppercase;margin-top:20px;}
.micinimo{font-family: Lobster;font-size:33px;color:#fff;margin-bottom:35px}
.micinimo4{font-family: Lobster;font-size:33px;color:#666;margin-bottom:15px}
.contact-form textarea#message {
    border: 1px solid #ccc;
    border-radius: 0px;
    height: 80px;
    
    width: 100%;
}
.contact-form textarea#message2 {
    border: 1px solid #ccc;
    border-radius: 0px;
    height: 80px;
    margin-bottom:10px;
    width: 100%;
}
.contact-form textarea#message9 {
    border: 1px solid #ccc;
    border-radius: 0px;
    height: 80px;
    margin-bottom:10px;
    width: 100%;
}
.form-control {
    height:46px;
    font-size: 0.9rem;
    padding:12px;
}
.bijelo{color:#9e9696;}
.bijelo:hover{color:#DDD;}
.crno{color:#000;}
.crno:hover{color:#666;}

.email{font-size:13px;}
.pagination .page-item a.page-link{color: #000;}
.pagination .page-item a:hover.page-link{color: darkgreen;}

.calculator-amortization .form{border:1px solid #000;padding:20px;}
.numbers{padding: 30px;border-top:1px solid #5ebb4d;border-bottom:1px solid #5ebb4d;background-color: ghostwhite;}
.bg-image-numbers{background-image: url("/public/images/euro.png"); opacity: 0.2%}
.numbers2{padding: 30px;border-top:1px solid #5ebb4d;border-bottom:1px solid #5ebb4d;background-color: ghostwhite;}
.thead-green{color: white;background-color: #5ebb4d;}
.numbers h3{padding: 0px;margin: 0px;}
.numbers .table td, .numbers .table th {
    width: 33.33%;
    padding: 10px;
}
.numbers .table td {
    background-color: white;
}
.numbers td h4{padding:0px;margin: 0px;font-size: 18px;}
.nav-tabs {
    border-bottom: none;

}
.nav-tabs .nav-link {
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    margin-bottom: -1px;
    color: #495057;
}
.nav-tabs .nav-link.active {
    background-color: #5ebb4d;
    color: #ffffff;
    border: 1px solid #5ebb4d;
}
.green-border{border: 1px solid #5ebb4d;}

.custom-btn {
    background-color: #eaf7ff; /* Svijetloplava pozadina */
    border: 1px solid #5ebb4d; /* Tamnoplavi okvir */
    color: #5ebb4d; /* Tamnoplavi tekst */
    font-weight: bold; /* Deblji tekst za bolju čitljivost */
    transition: all 0.3s ease; /* Dodaje animaciju za hover efekat */
    text-transform: uppercase;

}

.custom-btn i {
    margin-right: 8px; /* Razmak između ikone i teksta */
}

.custom-btn:hover {
    background-color: #fff; /* Tamnoplava pozadina na hover */
    color: #5ebb4d; /* Bijeli tekst na hover */
    border-color: #5ebb4d; /* Tamniji okvir na hover */
}

    