@media (min-width: 1920px) {
.client-details {padding: 6% 10%;}
.company-content {padding: 100px 60px 100px 30px;}
.vasprvi4{font-family: Teko, serif;font-size:70px;color:#000;letter-spacing:6px;text-transform:uppercase;margin-top:0px;margin-bottom:20px;}
.optimumgrupa4{color:#40b50e;text-transform:uppercase;margin-top:65px;margin-bottom:40px;font-size:40px;}
.micinimo4{font-family: Lobster, serif;font-size:26px;color:#666;margin-bottom:15px;margin-top:0px;}
.kontakt1{
	background-image: url('../../public/img/karta1.jpg');
    background-repeat: no-repeat;	
	background-position: center;
	height:440px;
}
.newEmployees{
	background-image: url('../../public/img/employees.jpg');
	background-repeat: no-repeat;
	background-color:#dfd8ce;
	background-position: center;
	min-height: 800px;
}

}

@media (min-width: 1170px) and (max-width: 1919px) {

  .client-details {
    padding: 6% 10%;
}

.newEmployees{
        background-image: url('../../public/img/employees.jpg');
        background-repeat: no-repeat;
        background-color:#dfd8ce;
        background-position: center;
        min-height: 800px;
    }
.company-content {
    padding: 100px 60px 100px 30px;
}

.vasprvi4{font-family: Teko;font-size:70px;color:#000;letter-spacing:6px;text-transform:uppercase;margin-top:0px;margin-bottom:20px;}
.optimumgrupa4{color:#40b50e;text-transform:uppercase;margin-top:65px;margin-bottom:40px;font-size:40px;}
.micinimo4{font-family: Lobster;font-size:26px;color:#666;margin-bottom:15px;margin-top:0px;}	
.sliderbg{
	background-image: url('../../public/img/ekipa1110.jpg');
    background-repeat: no-repeat;	
	background-color:#dfd8ce;
	background-position: center;
	height:500px;
}
}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1169px) {

.service-content h4 {
    font-size: 15px;
}
.image-layer {
    padding: 10px;
}
.single-services .service-content h4 {
    font-size: 14px;
}    
.sub-head h4 {
    font-size: 26px;
} 
.blog-content a h4 {
    font-size: 20px;
}    
.left-menu ul li a {
    font-size: 13px;
    padding: 14px 10px;
}     
.down-btn {
    font-size: 13px;
    padding: 15px 10px;
}    
.support-text {
    padding: 20px 5px;
}    
 .single-about {
    width: 48%;
    margin-right: 10px;
} 
.single-about h5 {
    font-size: 16px;
    padding-left: 5px;
}
.intro-area-2 .slider-content {
    margin-top: -100px;
}    


.vasprvi{font-family: Teko;font-size:52px;color:#fff;letter-spacing:10px;text-transform:uppercase;margin-top:25px;margin-bottom:15px;}
.optimumgrupa{color:#DDD;text-transform:uppercase;margin-top:80px;}
.micinimo{font-family: Lobster;font-size:33px;color:#fff;margin-bottom:35px}   

.vasprvi4{font-family: Teko;font-size:76px;color:#000;letter-spacing:6px;text-transform:uppercase;margin-top:0px;margin-bottom:20px;}
.optimumgrupa4{color:#40b50e;text-transform:uppercase;margin-top:65px;margin-bottom:40px;font-size:28px;}
.micinimo4{font-family: Lobster;font-size:24px;color:#666;margin-bottom:15px;margin-top:0px;}	
.sliderbg{
	background-image: url('../../public/img/ekipa1110.jpg');
    background-repeat: no-repeat;
	background-color:#dfd8ce;
	
	background-position: center;
	height:400px;}


.gumbrazmak{margin-bottom:0px;}

    .newEmployees{
        background-image: url('../../public/img/employees1169.jpg');
        background-repeat: no-repeat;
        background-color:#dfd8ce;
        background-position: center;
        min-height: 487px;
    }


}





/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {

    .newEmployees{
        background-image: url('../../public/img/employees991.jpg');
        background-repeat: no-repeat;
        background-color:#dfd8ce;
        background-position: center;
        min-height: 413px;
    }
.vasprvi{font-family: Teko, serif;font-size:56px;color:#fff;letter-spacing:10px;text-transform:uppercase;margin-top:0px;margin-bottom:0px;}
.optimumgrupa{color:#DDD;text-transform:uppercase;margin-top:40px;font-size:22px;}
.micinimo{font-family: Lobster, serif;font-size:18px;color:#fff;margin-bottom:20px}
.vasprvi4{font-family: Teko, serif;font-size:56px;color:#000;letter-spacing:6px;text-transform:uppercase;margin-top:0px;margin-bottom:20px;}
.optimumgrupa4{color:#40b50e;text-transform:uppercase;margin-top:55px;margin-bottom:20px;font-size:28px;}
.micinimo4{font-family: Lobster, serif;font-size:24px;color:#666;margin-bottom:15px;margin-top:0px;}

.gumbrazmak{margin-bottom:0px;}
	
.header-area .main-menu ul.navbar-nav li a{
    font-size: 13px;
    margin: 0px 8px;
	padding: 30px 0px;
}
.header-area.stick .main-menu ul.navbar-nav li a,
.header-area-3.stick .main-menu ul.navbar-nav li a {
    font-size: 13px;
    margin: 0px 8px;
    padding: 25px 0px;
}
.header-area-3 .main-menu ul.navbar-nav li a {
    padding: 20px 15px;
    color: #444;
    margin: 0px;
}
.header-area-3.stick .main-menu ul.navbar-nav li a {
    padding: 25px 15px;
	margin: 0px;
}
.header-middle-area .logo a {
    padding: 15px 0;
}
.header-middle-area .header-info {
    padding: 21px 0px;
}
.quote-button .quote-btn {
    padding: 14px 10px;
}
.logo a {
    padding: 16px 0;
}
.header-right-link a.main-search {
    margin: 20px 5px;
}
.header-area.stick .header-right-link a.main-search {
    margin: 15px 5px;
}
.section-headline h3 {
    font-size: 30px;
}
.sub-head h4 {
    font-size: 20px;
}
.slider-content {
    margin-top: -130px;
}  
.layer-1-2 h1 {
    font-size: 26px;
    line-height: 32px;
    max-width: 550px;
}
.layer-1-1 p {
    font-size: 17px;
    line-height: 24px;
    max-width: 570px;
}
.ready-btn {
    margin-top: 5px;
}
.main-services {
    padding: 20px 10px;
}
.well-services:hover .image-layer{
	padding: 10px;
}
.image-layer a {
    margin-bottom: 5px;
    font-size: 13px;
}
.service-content h4,
.single-services .service-content h4{
    font-size: 14px;
}
.services-img {
    max-height: 150px;
    height: 100%;
}
.services-img img {
    max-height: 150px;
    height: 100%;
}
.top-icon {
    position: absolute;
    right: 0px;
    font-size: 24px;
    bottom: 0px;
    background: #F6B129;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    border-radius: 5px 5px 0px 0px;
    z-index: 9999;
}
.fun_text {
	padding: 30px 6px;
}
.fun_text > h5 {
    font-size: 13px;
}
.fun_text span {
    font-size: 28px;
}
.banner-content h4 {
    font-size: 24px;
    max-width: 600px;
    line-height: 34px;
}
.left-menu ul li a {
    font-size: 13px;
    padding: 14px 10px;
}     
.down-btn {
    font-size: 13px;
    padding: 15px 10px;
}    
.support-text {
    padding: 20px 10px;
}
.single-page {
    margin: 15px 0px;
}   
.single-services-page .mar-row {
    margin-top: 20px;
}
.choose-wrapper {
    padding: 80px 30px 80px 30px;
	background: #f8f8f8;
}
.counter-inner p {
    font-size: 14px;
    line-height: 24px;
}
.header-area-2.stick .logo a,
.header-area-2 .logo a {
    padding: 10px 0;
}
.intro-area-2 .slider-content {
    margin-top: -60px;
}
.feature-text h4 {
    font-size: 18px;
} 
.single-contact h5 {
    font-size: 17px;
}
.contact-icon {
    padding: 30px 15px;
}    
.header-middle-area .header-info:first-child{
		display: none;
	}    
.header-middle-area .header-info {
    width: 50%;
    float: left;
}    	
.top-price-inner h4 {
    font-size: 20px;
}	
.contact-head h3 {
    font-size: 24px;
}	
.single-about {
    width: 48%;
    margin-right: 10px;
}	
.single-about h5 {
    font-size: 14px;
    padding-left: 5px;
}	
.about-content h4,
.video-text h4{
    font-size: 16px;
}	
.single-about a {
    font-size: 24px;
}	
.about-content p {
    font-size: 13px;
}
.project-details h4 {
    font-size: 22px;
}
.blog-content a h4 {
    font-size: 20px;
    line-height: 28px;
}
.project-dec h4 {
    font-size: 20px;
}
.blog-search-option input {
    width: 75%;
}

.intro-content,
.slider-images img{
    width: 100%;
   
	height: 401px;
}
	
}

/* small mobile :320px. */
@media (max-width: 767px) {
.container {width:300px}

.logo {
    height: inherit;
    left: 0;
    padding: 0;
    position: absolute;
    top: 4px;
    z-index: 999999;
    max-width: 130px;
}
.logo a {
    padding: 0px;
}
.pagess::after {
    display: none;
}
.slider-content {
    margin-top: -110px;
}
.layer-1-2 {
    margin: 5px 0;
}
.layer-1-2 h1 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
	letter-spacing: 0px;
} 
.layer-1-1 p {
    font-size: 13px;
    letter-spacing: 0px;
    line-height: 20px;
}
 .ready-btn.right-btn {
    margin-left: 5px;
}   
.ready-btn {
    margin-top: 0px;
    padding: 10px 0px;
    width: 128px;
	font-size: 12px;
}    


.section-headline h4 {
    font-size: 20px;
    line-height: 30px;
}
.about-content h4,
.video-text h4{
    line-height: 27px;
    font-size: 16px;
    margin-top: 15px;
}
.video-content {
    min-height: 200px;
}
.main-services {
    margin-bottom: 20px;
}
.section-headline h3 {
    font-size: 24px;
}
.breadcrumb .section-headline h3 {
    font-size: 24px;
    margin-bottom: 5px;
}
.counter-inner {
    padding: 100px 40px 100px 40px;
}
.counter-inner h4 {
    line-height: 30px;
    font-size: 20px;
}
.about-count {
    padding: 80px 40px 80px 40px;
}
.fun_text {
    width: 100%;
    margin: 10px;
}
.fun_text span {
    font-size: 22px;
}
.fun_text > h5 {
    font-size: 13px;
}
.single-team-member{
	margin-top: 30px;
}
.faq-content {
    padding: 5% 8%;
    margin-top: 30px;
}
.banner-content h4 {
    font-size: 20px;
    line-height: 26px;
}
.main-view {
    float: none;
    padding: 10px 10px;
    width: 100%;
}
.review-content.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-prev, 
.review-content.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-next,
.intro-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-prev, 
.intro-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-next{
    display: none !important;
}
.project-dec h4 {
    font-size: 20px;
}
.sub-head h4 {
    font-size: 24px;
}
.banner-contact span {
    font-size: 17px;
    margin-right: 6px;
}    
.banner-contact span i {
    margin-right: 10px;
}
.banner-btn {
    float: none;
    margin-top: 20px;
}
.footer-content {
    margin-bottom: 40px;
}   
.copyright {
    text-align: center;
    margin: 10px 0px;
}
.copyright ul {
    text-align: center;
}
.copyright ul li:first-child {
    margin-left:0px;
}
.copyright ul li a {
    text-align: center;
} 
.about-details {
    margin-bottom: 30px;
}
.single-about {
    width: 100%;
    margin-bottom: 0px;
}   
.video-text {
    margin-top: 30px;
}
.left-head-blog {
    margin-top: 40px;
}    
.business-faq {
    margin-bottom: 40px;
}    
.single-faq h3 {
    font-size: 18px;
}    
.project-menu li a {
    font-size: 13px;
    margin: 5px 3px;
    padding: 5px 6px;
} 
.project-details h3 {
    font-size: 26px;
}
.project-details{
    margin-bottom: 30px;
}
.project-history {
    padding: 50px 15px;
    margin-bottom: 30px;
}
.single-page {
    margin: 15px 0px;
}  
.single-services-page .mar-row {
    margin-top: 20px;
}    
.client-head {
    margin-left: 0px;
} 
.client-head h3 {
    font-size: 24px;
}
.intro-area.intro-area-2 {
    margin-top: 0px;
}
.intro-area-2 .slider-content {
    margin-top: -60px;
}
.contact-icon {
    padding: 20px 5px;
	margin-bottom: 40px;
}
.counter-area-2 .fun_text {
    float: none;
    width: 100%;
	margin-bottom: 20px;
}
.counter-area-2 .fun_text span {
    font-size: 36px;
}
.about-image{
	margin-bottom: 30px;
}
.texti-name {
    padding-left: 6px;
}
.error-easy-text {
    font-size: 24px;
}
.high-text {
    font-size: 100px;
    line-height: 120px;
}
.error-bot {
    font-size: 15px;
}
.single-well h3 {
    margin-bottom: 5px;
    font-size: 22px;
}
.single-well a h4 {
    font-size: 20px;
    margin-bottom: 10px;
}
.tab-menu ul.nav li a {
    padding: 10px 5px;
    margin-right: 0px;
    width: 90px;
    font-size: 12px;
}
.project-details h4 {
    font-size: 18px;
}
.marker-list li {
    font-size: 14px;
}
.contact-head {
    padding: 40px 10px;
}
.contact-head h3 {
    font-size: 20px;
}
.single-contact a {
    font-size: 14px;
}	
.choose-wrapper {
    background: #f9f9f9;
    padding: 80px 30px 80px 30px;
}
.contact-form.news-form .form-control {
    float: none;
    width: 100%;
}
.contact-form.news-form .add-btn {
    float: none;
    margin-left: 0px;
}
.intro-area-2 .intro-content, 
.intro-area-2 .slider-images img {
    width: 100%;
    height: 100%;
    min-height: 320px;
}
.choose-services {
    margin: 10px 0px;
}	
.blog-content a h4 {
    font-size: 17px;
    line-height: 26px;
}	
.blog-details .blog-content a h4 {
    font-size: 18px;
    line-height: 26px;
}	

.vasprvi{font-family: Teko;font-size:26px;color:#fff;letter-spacing:8px;text-transform:uppercase;margin-top:0px;margin-bottom:0px;}
.vasprvi4{font-family: Teko;font-size:36px;color:#000;letter-spacing:6px;text-transform:uppercase;margin-top:0px;margin-bottom:0px;}
.optimumgrupa{color:#DDD;text-transform:uppercase;margin-top:10px;font-size:22px;}
.optimumgrupa4{color:#40b50e;text-transform:uppercase;margin-top:85px;margin-bottom:0px;font-size:18px;}
.micinimo{font-family: Lobster;font-size:18px;color:#fff;margin-bottom:10px}  
.micinimo4{font-family: Lobster;font-size:18px;color:#666;margin-bottom:15px;margin-top:0px;}
.gumbrazmak{margin-bottom:10px;}	
.sliderbg{/*background-image: url('/img/ekipa480.jpg');*/
    background-repeat: no-repeat;
	background-color:#dfd8ce;
background-position: center;
height:201px;}
	.sliderbgall{
		background-image: url('../../public/img/employees767.jpg');
		background-repeat: no-repeat;
		background-color:#dfd8ce;
		background-size: cover;
		background-position: center;min-height: 320px;
	}
.btn-dark{font-size:12px;}
.btn-success{font-size:12px;}

    
}

/* Large Mobile :480px. */
@media only screen and (min-width: 380px) and (max-width: 767px) {
.container {width:380px}
.fun_text {
    width: 31%;
    margin: 5px;
}

.vasprvi{font-family: Teko;font-size:32px;color:#fff;letter-spacing:10px;text-transform:uppercase;margin-top:0px;margin-bottom:0px;}
.vasprvi4{font-family: Teko;font-size:60px;color:#000;letter-spacing:10px;text-transform:uppercase;margin-top:20px;margin-bottom:5px;}
.optimumgrupa{color:#DDD;text-transform:uppercase;margin-top:5px;font-size:18px;}
.optimumgrupa4{color:#40b50e;text-transform:uppercase;margin-top:75px;font-size:24px;}
.micinimo{font-family: Lobster;font-size:14px;color:#fff;margin-bottom:10px}    
.micinimo4{font-family: Lobster;font-size:20px;color:#666;margin-bottom:15px}
.btn-dark{font-size:22px;height:300px;}
.btn-success{font-size:22px;}

    .newEmployees{
        background-image: url('../../public/img/employees767.jpg');
        background-repeat: no-repeat;
        background-color:#dfd8ce;
        background-position: center;
        min-height: 320px;
    }


}
 
