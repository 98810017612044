.text-dark{
  color:#828282!important;
}
.text-black{
  color:#4c4546!important;
}
.btn{
  border-radius: 5px;
}
.btn-dark {
  font-weight: 600;
    color: #fff;
    background-color: #343a40;
    border-color: #000;	
}
.btn-dark:hover{
  color: #fff;
  background-color: #2a2f33;
}
.btn-success {
    font-weight: 600;
    color: #fff;
    background-color: #4CAF51;
    border-color: #4cae4c;
}
.mt-30 {
    margin-top: 30px;
}
.height-txt{
  font-size: 100px;
  line-height: 200px;
}
.index-txt{
max-width: 680px;
font-size: 16px;
letter-spacing: 0.5px;
margin: 0 auto;
line-height: 26px;
}
.index-txt-question{
    font-size: 16px;
    letter-spacing: 0.5px;
    margin: 0 auto;
    line-height: 26px;
}
.bg-grey{
  background-color: #eee;
}
.mb-2{
  margin-bottom: 2rem;
}
.icon-color{
  color: #1c8c4b;
}
.infofont p strong{font-size:16px;color:#000;}
.infofont p {font-size:16px;color:#5d5858;}

.video-container {
    position: relative;
    width: 100%; /* Prilagodite širinu po potrebi */
    margin: 0 auto; /* Centriranje na sredini stranice */
}
.video-container::before {
    content: "";
    display: block;
    padding-top: 56.25%; /* Omjer 16:9 (9 / 16 * 100) */
}

.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-container .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    z-index: 1;
    text-align: center;
}
.video-container #toggle-sound {
    position: absolute;
    bottom: 60px; /* Spuštanje 50px od dna */
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    font-size: 16px;
    background: none;
    color: white;
    border: none;
    cursor: pointer;
    z-index: 2; /* Postavljamo iznad videa */
}
.video-container #restart-video {
    position: absolute;
    bottom: 30px; /* Spuštanje 50px od dna */
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    font-size: 16px;
    background: none;
    color: white;
    border: none;
    cursor: pointer;
    z-index: 2; /* Postavljamo iznad videa */
}